
import {
  ProductAdvancePrice,
  ProductAttribute,
  ProductAttributeImage,
  ProductConfigAttribute,
  ProductDetail,
  ProductDetailImage,
} from "@/types/product/ProductDetail.interface";
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import ProductImage from "./ProductImage.vue";
import ProductConfigOption from "./ProductConfig.vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    productDetail: {
      required: true,
      type: Object as PropType<ProductDetail>,
    },
  },
  components: {
    ProductImage,
    ProductConfigOption,
  },
  emits: ["onAddProductToCart"],
  setup(props, { emit }) {
    const productImages = ref<ProductDetailImage[]>([]);
    const productName = ref<string>();
    const oldProductName = ref<string>();
    const productAdvancePrice = ref<ProductAdvancePrice>();
    const store = useStore();
    const quantity = ref<number>(1);
    const productDescription = ref<string>();
    const parentProduct = ref<ProductDetail>();
    const isProductConfig = ref<boolean>(false);
    const productAttributes = ref<ProductAttribute[]>([]);
    const productChildren = ref<ProductConfigAttribute>();
    const productAttributeImage = ref<ProductAttributeImage[]>([]);
    const forceRefresh = ref<boolean>(true);
    const isProductOutOfStock = ref<boolean>(false);
    const productSelected = ref<ProductDetail>();

    const onSetProductImage = () => {
      if (props.productDetail.productTypeId == 1) {
        const imageObj = {
          base64: "",
          imageUrl: props.productDetail.imageUrl,
          productImageId: 0,
          display: true,
          translation: [],
          sortOrder: 0,
        };
        productImages.value?.push(imageObj);
        productName.value = props.productDetail.translationList[0].name;
        productAdvancePrice.value = props.productDetail.advancePrice;
        productAdvancePrice.value.price = props.productDetail.price;
        productDescription.value =
          props.productDetail.translationList[0].description;
        productSelected.value = props.productDetail;
        handleForcefresh();
      } else {
        parentProduct.value = props.productDetail;
        productImages.value = props.productDetail.productImage;
        isProductConfig.value = true;
        productAttributes.value = props.productDetail.attributes;
        productChildren.value = props.productDetail.children;
        productName.value = props.productDetail.translationList[0].name;
        oldProductName.value = props.productDetail.translationList[0].name;

        productDescription.value =
          props.productDetail.translationList[0].description;
        productAttributeImage.value = props.productDetail.productAttributeImage;
        handleForcefresh();
      }
    };

    const onGetProductConfig = (
      product: ProductDetail,
      isOutOfStock: boolean,
      productImage: ProductAttributeImage
    ) => {
      if (!isOutOfStock) {
        isProductOutOfStock.value = isOutOfStock;
      } else {
        productName.value = product.translationList[0]?.name;
      }
      if (productImage) {
        productImages.value = [];
        productImage.image.forEach((element) => {
          const imageObj = {
            base64: "",
            imageUrl: element.imageUrl,
            productImageId: 0,
            display: true,
            translation: [],
            sortOrder: 0,
          };
          productImages.value.push(imageObj);
        });
      }

      productSelected.value = product;
      productName.value = product.translationList[0]?.name;
      productAdvancePrice.value = product.advancePrice;
      productAdvancePrice.value.price = product.price;
    };

    const handleForcefresh = () => {
      forceRefresh.value = false;
      setTimeout(() => {
        forceRefresh.value = true;
      }, 100);
    };

    const onAddProductToCart = (cartFlag: boolean) => {
      emit(
        "onAddProductToCart",
        productSelected.value,
        quantity.value,
        cartFlag
      );
    };

    const onAddQuantity = () => {
      quantity.value = quantity.value + 1;
    };

    const onRemoveQuantity = () => {
      if (quantity.value > 1) {
        quantity.value = quantity.value - 1;
      }
    };

    onMounted(() => {
      onSetProductImage();
    });

    return {
      productImages,
      productName,
      productAdvancePrice,
      quantity,
      productDescription,
      isProductConfig,
      parentProduct,
      productChildren,
      productAttributeImage,
      forceRefresh,
      onAddQuantity,
      onRemoveQuantity,
      onGetProductConfig,
      onAddProductToCart,
      backgroundColor: computed(() => store.getters.getBrand.colorThemeMain),
    };
  },
});
